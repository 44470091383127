import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../static/styles/index.scss';
import { graphql } from 'gatsby';
import ReactPageScroller from '../../lib/index';

import Layout from '../../layouts/en';
import SEO from '../../components/seo';
import MainSection from '../../pages-sections/about-sections/main-section/main-section';
import LineIndicator from '../../components/line-indicator/line-indicator';
import MissionsSection from '../../pages-sections/about-sections/missions-section/missions-section';
import Pagination from '../../components/pagination/pagination';
import DirectorsSection from '../../pages-sections/about-sections/directors-section/directors-section';
import QuoteSection from '../../pages-sections/about-sections/quote-section/quote-section';
import ManagersSection from '../../pages-sections/about-sections/managers-section/managers-section';
import HistorySection from '../../pages-sections/about-sections/history-section/history-section';
import {
  returnNum,
  getPageData,
  setRtl,
  unsetRtl,
} from '../../helpers/helpers';
import useGoToPage from '../../hooks/useGoToPage';

const AboutPage = ({ location, data }) => {
  const locationLink = location.hash.length ? returnNum(location.hash) : 1;
  const [currentPage, setCurrentPage] = useState(locationLink);
  const [lineHeight, setLineHeight] = useState(0);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const [canScrollUpOnHover, setCanScrollUpOnHover] = useState(false);
  const [canScrollDownOnHover, setCanScrollDownOnHover] = useState(false);
  const [windowSize, setWindowSize] = useState(0);
  const [showSections, setShowSections] = useState(false);
  const [sliderDirectors, setSliderDirectors] = useState(null);
  const [sliderManagers, setSliderManagers] = useState(null);
  const wpDataSliderDirectors = data && data.allWordpressWpSliderDirectors.edges;
  const wpDataSliderManagers = data && data.allWordpressWpSliderManagers.edges;
  useEffect(() => {
    setSliderDirectors(wpDataSliderDirectors);
    setSliderManagers(wpDataSliderManagers);
  }, [data]);

  const isMobile = windowSize <= 1024;
  const wpData = data && getPageData(data, 'about-us-ar');
  useEffect(() => {
    setRtl(location.pathname);
    return () => {
      unsetRtl();
    };
  }, []);
  const MENU_ITEMS = [
    wpData.section_title_1,
    wpData.section_title_2,
    wpData.section_title_5,
    wpData.section_title_3,
    wpData.section_title_4,
  ]; // 'Quote' add when show section
  const [ref, goToPage] = useGoToPage(location, isMobile, MENU_ITEMS, currentPage);

  const onChangeSection = ({ index, name }) => {
    goToPage(index, name);
  };

  const handleWindowSizeChange = () => {
    const width = window.innerWidth || document.documentElement.clientWidth
  || document.body.clientWidth;
    setWindowSize(width);
  };

  const handleWindowScroll = () => {
    const scrollBlocker = window.pageYOffset > 0 || window.pageYOffset < 0;

    setCanScrollUp(scrollBlocker);
    return true;
  };

  useEffect(() => {
    handleWindowSizeChange(); // Set width
    window.addEventListener('resize', handleWindowSizeChange);
    if (!isMobile) {
      document.addEventListener('scroll', handleWindowScroll, { passive: false });
      setShowSections(false);
    } else {
      setShowSections(true);
    }
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isMobile]);

  useEffect(() => {
    const activePaginationButton = document.querySelector('button[data-activeitem="active"]');
    const topPosition = activePaginationButton
      ? activePaginationButton.getBoundingClientRect().top + 20 : 486;
    setLineHeight(topPosition);
    setTimeout(() => {
      if (showSections !== (currentPage === MENU_ITEMS.length + 1)) {
        setShowSections(currentPage === MENU_ITEMS.length + 1);
      }
    }, 1000); // time for render sections that are out of pageScroller
  }, [currentPage, windowSize]);

  if (windowSize === 0) {
    return (<></>);
  }

  // if (windowSize < 1024) {
  //   return (<MobilePlaceholder />);
  // }

  const renderSections = () => (
    [
      <MainSection
        activeSection={currentPage}
        title={wpData.section_1_name}
        isMobile={isMobile}
        data={
                {
                  title: wpData.section_1_title,
                  titleBold: wpData.section_1_title_bold,
                  image: wpData.section_1_background_image,
                }
              }
      />,
      <MissionsSection
        activeSection={currentPage}
        isMobile={isMobile}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_1}
          />
        )}
        sectionName={wpData.section_title_1}
        data={
                {
                  textTitle1: wpData.section_2_text_title,
                  textTitle2: wpData.section_2_text_title_2,
                  textBody1: wpData.section_2_text_1,
                  textBody2: wpData.section_2_text_2,
                  image: wpData.section_1_background_image,
                }
              }
      />,
      <DirectorsSection
        dataSlider={sliderDirectors}
        location={location}
        activeSection={currentPage}
        title={wpData.section_title_2}
        index={1}
        setCanScrollDown={setCanScrollDownOnHover}
        setCanScrollUp={setCanScrollUpOnHover}
        isMobile={isMobile}
        sectionName={wpData.section_title_2}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_2}
          />
        )}
      />,
      <QuoteSection
        isMobile={isMobile}
        activeSection={currentPage}
        sectionName={wpData.section_title_5}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_5}
          />
        )}
        data={
          {
            quote: wpData.section_4_quote,
            quoteAuthor: wpData.section_4_quote_author,
            quoteAuthorPosition: wpData.section_4_quote_author_position,
          }
        }
      />,
      <ManagersSection
        dataSlider={sliderManagers}
        activeSection={currentPage}
        title={wpData.section_title_3}
        index={3}
        location={location}
        isMobile={isMobile}
        setCanScrollDown={setCanScrollDownOnHover}
        setCanScrollUp={setCanScrollUpOnHover}
        sectionName={wpData.section_title_3}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_3}
          />
        )}
      />,
      <HistorySection
        location={location}
        activeSection={currentPage}
        title={MENU_ITEMS[currentPage - 2]}
        index={4}
        isMobile={isMobile}
        sectionName={wpData.section_title_4}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_4}
          />
        )}
        data={
          {
            number1: wpData.section_6_number_1,
            text1: wpData.section_6_text_1,
            number2: wpData.section_6_number_2,
            text2: wpData.section_6_text_2,
            number3: wpData.section_6_number_3,
            text3: wpData.section_6_text_3,
            number4: wpData.section_6_number_4,
            text4: wpData.section_6_text_4,
            number5: wpData.section_6_number_5,
            text5: wpData.section_6_text_5,
            number6: wpData.section_6_number_6,
            text6: wpData.section_6_text_6,
            number7: wpData.section_6_number_7,
            text7: wpData.section_6_text_7,
            number8: wpData.section_6_number_8,
            text8: wpData.section_6_text_8,
            number13: wpData.section_6_number_13,
            text13: wpData.section_6_text_13,
            number14: wpData.section_6_number_14,
            text14: wpData.section_6_text_14,
            number9: wpData.section_6_number_9,
            text9: wpData.section_6_text_9,
            number15: wpData.section_6_number_15,
            text15: wpData.section_6_text_15,
            number16: wpData.section_6_number_16,
            text16: wpData.section_6_text_16,
            number10: wpData.section_6_number_10,
            text10: wpData.section_6_text_10,
            number17: wpData.section_6_number_17,
            text17: wpData.section_6_text_17,
            number18: wpData.section_6_number_18,
            text18: wpData.section_6_text_18,
            number19: wpData.section_6_number_19,
            text19: wpData.section_6_text_19,
            number11: wpData.section_6_number_11,
            text11: wpData.section_6_text_11,
            number12: wpData.section_6_number_12,
            text12: wpData.section_6_text_12,
            image: wpData.section_1_background_image,
          }
        }
      />,
    ]
  );

  return (
    <>
      <Layout
        currentSection={currentPage}
        sectionsCount={MENU_ITEMS.length + 1}
        location={location}
        isMobile={isMobile}
        changeHandler={onChangeSection}
        showFooter={showSections}
        footerData={
          {
            footerLink1: wpData.footer_link_1,
            footerLink2: wpData.footer_link_2,
            footerLink3: wpData.footer_link_3,
            footerLink4: wpData.footer_link_4,
            footerLink5: wpData.footer_link_5,
            footerLink6: wpData.footer_link_6,
            footerLink7: wpData.footer_link_7,
            footerEmailLabel: wpData.footer_email_label,
            footerEmail: wpData.footer_email,
            footerPhoneLabel: wpData.footer_phone_label,
            footerPhone: wpData.footer_phone,
            copyright: wpData.copyright,
          }
        }
      >
        <SEO title={wpData.section_1_name} />
        <div data-currentPage={`currentPage_${currentPage}`}>
          {windowSize > 1024 ? (
            <ReactPageScroller
              ref={ref}
              pageOnChange={(num) => setCurrentPage(num)}
              customPageNumber={currentPage}
              transitionTimingFunction="cubic-bezier(.435,.01,.135,1)"
              blockScrollUp={canScrollUpOnHover || canScrollUp}
              blockScrollDown={canScrollDownOnHover || showSections}
              pagination={(
                <Pagination
                  canScrollUp={canScrollUp}
                  menuItems={MENU_ITEMS}
                  changeHandler={onChangeSection}
                  activeItem={currentPage}
                />
            )}
            >
              {renderSections().map((section) => section)}
            </ReactPageScroller>
          ) : (
            <div ref={ref}>
              {renderSections().map((section) => section)}
            </div>
          )}
          <LineIndicator
            height={lineHeight}
            activeItem={currentPage}
          />
        </div>
      </Layout>
    </>
  );
};

AboutPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};
AboutPage.defaultProps = {
  data: null,
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          acf {

            section_title_1
            section_title_2
            section_title_3
            section_title_4
            section_title_5

            section_1_title
            section_1_title_bold
            section_1_name
            section_1_background_image {
              alt
              url
            }
            section_2_text_title
            section_2_text_title_2
            section_2_text_1
            section_2_text_2
            section_2_background_image {
              alt
              url
            }

            section_3_name_1
            section_3_position_1
            section_3_text_paragraph_1
            section_3_text_paragraph_2
            section_3_text_paragraph_3
            section_3_text_list_item_1
            section_3_text_list_item_2
            section_3_text_list_item_3
            section_3_text_list_item_4
            section_3_text_list_item_5
            section_3_image_1 {
              alt
              url
            }

            section_3_name_2
            section_3_position_2
            section_3_text_2_paragraph_1
            section_3_text_2_paragraph_2
            section_3_text_2_paragraph_3
            section_3_text_list_2_item_1
            section_3_text_list_2_item_2
            section_3_text_list_2_item_3
            section_3_text_list_2_item_4
            section_3_text_list_2_item_5
            section_3_text_list_2_item_6
            section_3_text_list_2_item_7
            section_3_image_2 {
              alt
              url
            }

            section_3_name_3
            section_3_text_3_paragraph_1
            section_3_text_3_paragraph_2
            section_3_text_3_paragraph_3
            section_3_text_list_3_item_1
            section_3_text_list_3_item_2
            section_3_text_list_3_item_3
            section_3_text_list_3_item_4
            section_3_image_3 {
              alt
              url
            }

            section_3_name_4
            section_3_text_4_paragraph_1
            section_3_text_4_paragraph_2
            section_3_text_4_paragraph_3
            section_3_text_4_paragraph_4
            section_3_text_list_4_item_1
            section_3_text_list_4_item_2
            section_3_text_list_4_item_3
            section_3_image_4 {
              alt
              url
            }

            section_3_name_5
            section_3_text_5_paragraph_1
            section_3_text_5_paragraph_2
            section_3_text_list_5_item_1
            section_3_text_list_5_item_2
            section_3_text_list_5_item_3
            section_3_text_list_5_item_4
            section_3_image_5 {
              alt
              url
            }

            section_3_name_6
            section_3_text_6_paragraph_1
            section_3_text_6_paragraph_2
            section_3_text_list_6_item_1
            section_3_text_list_6_item_2
            section_3_image_6 {
              alt
              url
            }

            section_4_quote
            section_4_quote_author
            section_4_quote_author_position

            section_5_name_1
            section_5_position_1
            section_5_text_paragraph_1
            section_5_text_paragraph_2
            section_5_text_paragraph_3
            section_5_text_list_item_1
            section_5_text_list_item_2
            section_5_text_list_item_3
            section_5_image_1 {
              alt
              url
            }

            section_5_name_2
            section_5_position_2
            section_5_text_2_paragraph_1
            section_5_text_2_paragraph_2
            section_5_text_2_paragraph_3
            section_5_text_list_2_item_1
            section_5_text_list_2_item_2
            section_5_text_list_2_item_3
            section_5_text_list_2_item_4
            section_5_image_2 {
              alt
              url
            }
            section_5_name_3
            section_5_position_3
            section_5_text_3_paragraph_1
            section_5_text_3_paragraph_2
            section_5_image_3 {
              alt
              url
            }
            section_5_name_4
            section_5_position_4
            section_5_text_4_paragraph_1
            section_5_text_4_paragraph_2
            section_5_text_4_paragraph_3
            section_5_text_list_4_item_1
            section_5_text_list_4_item_2
            section_5_text_list_4_item_3
            section_5_text_list_4_item_4
            section_5_image_4 {
              alt
              url
            }
            section_5_name_5
            section_5_position_5
            section_5_text_5_paragraph_1
            section_5_text_5_paragraph_2
            section_5_image_5 {
              alt
              url
            }

            section_6_number_1
            section_6_text_1
            section_6_number_2
            section_6_text_2
            section_6_number_3
            section_6_text_3
            section_6_number_4
            section_6_text_4
            section_6_number_5
            section_6_text_5
            section_6_number_6
            section_6_text_6
            section_6_number_7
            section_6_text_7
            section_6_number_8
            section_6_text_8
            section_6_number_9
            section_6_text_9
            section_6_number_10
            section_6_text_10
            section_6_number_11
            section_6_text_11
            section_6_number_12
            section_6_text_12
            section_6_number_13
            section_6_text_13
            section_6_number_14
            section_6_text_14
            section_6_number_15
            section_6_text_15
            section_6_number_16
            section_6_text_16
            section_6_number_17
            section_6_text_17
            section_6_number_18
            section_6_text_18
            section_6_number_19
            section_6_text_19
            section_6_background_image {
              alt
              url
            }

            footer_link_1
            footer_link_2
            footer_link_3
            footer_link_4
            footer_link_5
            footer_link_6
            footer_link_7
            footer_email_label
            footer_email
            footer_phone_label
            footer_phone
            copyright
          }
          title
          slug
        }
      }
    }
    allWordpressWpSliderDirectors(
      filter: {
        categories: {
          eq: 17
        }
      }
      sort: {
        fields: [date]
      }
    ) {
      edges {
        node {
          acf {
            director_name
            director_position
            director_paragraph_1
            director_paragraph_2
            director_paragraph_3
            director_list_item_1
            director_list_item_2
            director_list_item_3
            director_list_item_4
            director_list_item_5
            director_list_item_6
            director_list_item_7
            director_image {
              alt
              url
            }
          }
        }
      }
    }
    allWordpressWpSliderManagers(
      filter: {
        categories: {
          eq: 18
        }
      }
      sort: {
        fields: [date]
      }
    ) {
      edges {
        node {
          acf {
            director_name
            director_position
            director_paragraph_1
            director_paragraph_2
            director_paragraph_3
            director_list_item_1
            director_list_item_2
            director_list_item_3
            director_list_item_4
            director_list_item_5
            director_list_item_6
            director_list_item_7
            director_image {
              alt
              url
            }
          }
        }
      }
    }
  }`;
