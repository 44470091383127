import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import styles from './quote-section.module.scss';
import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';
import LineIndicator from '../../../components/line-indicator/line-indicator';
import { replaceSpace } from '../../../helpers/helpers';

const QuoteSection = ({
  activeSection, isMobile, Pagination, data, sectionName,
}) => {
  const [lineHeight, setLineHeight] = useState(0);

  const handleWindowSizeChange = () => {
    const pagination = document.querySelector(`.${styles.animateIn} > div`);
    const topPosition = pagination
      ? `calc(${window.getComputedStyle(pagination).top} + 15px)` : 0;
    setLineHeight(topPosition);
  };
  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    window.addEventListener('scroll', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('scroll', handleWindowSizeChange);
    };
  }, []);
  useEffect(() => {
    if (lineHeight !== 0) {
      window.removeEventListener('scroll', handleWindowSizeChange);
    }
  }, [lineHeight]);
  const renderSection = () => (
    <section id={replaceSpace(sectionName)} data-active={`active_${activeSection}`} className={`${styles.quoteSection} ${styles.sections}`}>
      <VerticalLines />
      <Container>
        <blockquote className={styles.animatedTextContainer}>
          <div className={styles.animatedText}>
            <p className={styles.blockquote}>
              {data.quote}
            </p>
          </div>
          <div />
          <div className={styles.animatedText}>
            <cite className={styles.blockquote_cite}>{data.quoteAuthor}</cite>
          </div>
          <div className={styles.animatedText}>
            <cite className={`${styles.blockquote_cite} ${styles.blockquote_cite_second}`}>{data.quoteAuthorPosition}</cite>
          </div>
        </blockquote>

      </Container>
    </section>
  );
  return (
    isMobile
      ? (
        <ScrollAnimation animateOnce initiallyVisible animateIn={styles.animateIn}>
          {Pagination}
          <LineIndicator
            height={lineHeight}
          />
          {renderSection()}
        </ScrollAnimation>
      ) : renderSection()

  );
};

QuoteSection.propTypes = {
  activeSection: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  Pagination: PropTypes.node.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  sectionName: PropTypes.string.isRequired,

};

export default QuoteSection;
