/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import styles from './history-section.module.scss';
import CarouselBlock from '../../../components/carousel/carousel';
import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';
import { replaceSpace } from '../../../helpers/helpers';
import LineIndicator from '../../../components/line-indicator/line-indicator';

const numbers = ['٠١', '٠٢', '٠٣', '٠٤', '٠٥', '٠٦', '٠٧', '٠٨', '٠٩', '١٠', '١١', '١٢', '١٣', '١٤', '١٥', '١٦', '١٦', '١٧', '١٨'];

const HistorySection = ({
  activeSection, index, data, isMobile, sectionName, Pagination, location,
}) => {
  const [currentNumber, setCurrentNumber] = useState(1);
  const [lineHeight, setLineHeight] = useState(0);
  const [isAr, setIsAr] = useState(false);
  useEffect(() => {
    setIsAr(location.pathname.indexOf('/ar') !== -1);
  }, [location]);
  const handleWindowSizeChange = () => {
    const pagination = document.querySelector(`.${styles.animateIn} > div`);
    const topPosition = pagination
      ? `calc(${window.getComputedStyle(pagination).top} + 80px)` : 0;
    setLineHeight(topPosition);
  };
  useEffect(() => {
    if (isMobile) {
      handleWindowSizeChange();
      window.addEventListener('resize', handleWindowSizeChange);
      window.addEventListener('scroll', handleWindowSizeChange);
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('scroll', handleWindowSizeChange);
    };
  }, [isMobile]);
  useEffect(() => {
    if (lineHeight !== 0) {
      window.removeEventListener('scroll', handleWindowSizeChange);
    }
  }, [lineHeight]);
  const setNumber = (num) => {
    setCurrentNumber(num);
  };

  const dataActive = `active_${activeSection}`;
  const renderSection = () => (
    <section id={replaceSpace(sectionName)} data-active={dataActive} className={`current-${index} ${styles.historySection} ${styles.sections} v2`}>
      <div className={styles.historySectionOverlay} style={{ backgroundImage: `url(${data.image.url})` }} />
      <VerticalLines />
      <CarouselBlock
        getCurrentNumber={setNumber}
        showDots={false}
        index={index}
      >
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={styles.year}>{data.number1}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text1}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number2}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text2}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number3}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text3}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number4}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text4}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number5}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text5}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number6}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text6}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number7}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text7}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number8}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text8}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number13}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text13}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number14}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`.slice(-2)}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text14}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number9}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`.slice(-2)}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text9}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number15}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`.slice(-2)}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text15}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number16}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`.slice(-2)}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text16}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number10}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`.slice(-2)}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text10}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number17}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`.slice(-2)}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text17}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number18}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`.slice(-2)}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text18}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number19}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`.slice(-2)}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text19}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number11}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`.slice(-2)}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text11}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className={styles.slide}>
            <div className={`${styles.slideContainer}`}>
              <div className={`animatedSliderText ${styles.year_container}`}>
                <div className={`${styles.year}`}>{data.number12}</div>
              </div>
              <div className={`${styles.animatedTextContainer}`}>
                <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                  {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`.slice(-2)}
                </h5>
                <p className={`animatedSliderText ${styles.p_decription}`}>{data.text12}</p>
                {/* <div className={styles.text_divider} /> */}
              </div>
            </div>
          </div>
        </Container>
      </CarouselBlock>
    </section>
  );
  return (
    isMobile
      ? (
        <ScrollAnimation animateOnce initiallyVisible animateIn={styles.animateIn}>
          {Pagination}
          <LineIndicator
            height={lineHeight}
          />
          {renderSection()}
        </ScrollAnimation>
      ) : renderSection()
  );
};
HistorySection.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  activeSection: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool.isRequired,
  Pagination: PropTypes.node.isRequired,
  sectionName: PropTypes.string.isRequired,
};

export default HistorySection;
