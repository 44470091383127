// Core
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

// Instruments
import styles from './managers-section.module.scss';
import CarouselBlock from '../../../components/carousel/carousel';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';
import { replaceSpace } from '../../../helpers/helpers';
import LineIndicator from '../../../components/line-indicator/line-indicator';

const numbers = ['٠١', '٠٢', '٠٣', '٠٤', '٠٥', '٠٦', '٠٧', '٠٨', '٠٩', '١٠', '١١', '١٢', '١٥'];

const ManagersSection = ({
  dataSlider,
  activeSection,
  title,
  index,
  location,
  setCanScrollDown,
  setCanScrollUp,
  isMobile,
  sectionName,
  Pagination,
}) => {
  const [currentNumber, setCurrentNumber] = useState(1);
  const [lineHeight, setLineHeight] = useState(0);
  const [isAr, setIsAr] = useState(false);
  useEffect(() => {
    setIsAr(location.pathname.indexOf('/ar') !== -1);
  }, [location]);
  const handleWindowSizeChange = () => {
    const pagination = document.querySelector(`.${styles.animateIn} > div`);
    const topPosition = pagination
      ? `calc(${window.getComputedStyle(pagination).top} + 60px)` : 0;
    setLineHeight(topPosition);
  };
  useEffect(() => {
    if (isMobile) {
      handleWindowSizeChange();
      window.addEventListener('resize', handleWindowSizeChange);
      window.addEventListener('scroll', handleWindowSizeChange);
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('scroll', handleWindowSizeChange);
    };
  }, [isMobile]);
  useEffect(() => {
    if (lineHeight !== 0) {
      window.removeEventListener('scroll', handleWindowSizeChange);
    }
  }, [lineHeight]);
  useEffect(() => {
    const div = document.querySelector(`.slick-slide.slick-active.slick-current .${styles.animatedTextContainer}`);
    const hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
    if (hasVerticalScrollbar) {
      div.addEventListener('mouseover', () => {
        setCanScrollDown(true);
        setCanScrollUp(true);
      });
      div.addEventListener('mouseleave', () => {
        setCanScrollDown(false);
        setCanScrollUp(false);
      });
    }
  }, [currentNumber]);
  const setNumber = (num) => {
    setCurrentNumber(num);
  };
  const dataActive = `active_${activeSection}`;
  const renderSectionJSX = () => (
    <section
      id={replaceSpace(sectionName)}
      data-active={dataActive}
      className={`current-${index} ${styles.managersSection} ${styles.sections} v5`}
    >
      {isMobile && <VerticalLines />}
      <div className={styles.sectionTitle}>{`${title}`}</div>
      <CarouselBlock
        getCurrentNumber={setNumber}
        index={index}
        isMobile={isMobile}
      >
        {dataSlider.map((item) => {
          const { node: { acf: slide } } = item;
          return (
            <div className={styles.slide}>
              <div className={`${styles.animatedTextContainerBox}`}>
                <div className={`${styles.animatedTextContainer}`}>
                  <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_num}`}>
                    {isAr ? `${numbers[currentNumber - 1]}` : `0${currentNumber}`}
                  </h5>
                  <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_bold}`}>
                    {slide.director_name}
                  </h5>
                  <h5 className={`animatedSliderText ${styles.h5} ${styles.h5_light}`}>
                    {slide.director_position}
                  </h5>
                  <div className={styles.divider_p_decription} />
                  <p className={`animatedSliderText ${styles.p_decription}`}>
                    {slide.director_paragraph_1}
                  </p>
                  <div className={styles.divider_p_decription_inner} />
                  <p className={`animatedSliderText ${styles.p_decription}`}>
                    {slide.director_paragraph_2}
                  </p>
                  <div className={styles.divider_p_list_header} />
                  <div className="animatedSliderText">
                    <p className={`${styles.p_list_header}`}>{slide.director_paragraph_3}</p>
                    <p className={`${styles.p_list}`}>{slide.director_list_item_1}</p>
                    <p className={`${styles.p_list}`}>{slide.director_list_item_2}</p>
                    <p className={`${styles.p_list}`}>{slide.director_list_item_3}</p>
                  </div>
                  <div className="animatedSliderText">
                    <p className={`${styles.p_list}`}>{slide.director_list_item_4}</p>
                    <p className={`${styles.p_list}`}>{slide.director_list_item_5}</p>
                    <p className={`${styles.p_list}`}>{slide.director_list_item_6}</p>
                    <p className={`${styles.p_list}`}>{slide.director_list_item_7}</p>
                  </div>
                </div>
                <div className={`${styles.animatedTextContainer}`}>
                  <p className={`animatedSliderText ${styles.p_list} ${styles.p_list_second_font} ${styles.p_list_second_font_medium}`}>
                    {slide.director_name}
                  </p>
                  <p className={`animatedSliderText ${styles.p_list} ${styles.p_list_second_font}`}>
                    {slide.director_position}
                  </p>
                </div>
              </div>
              <img src={slide.director_image.url} className={styles.image} alt="" />
            </div>
          );
        })}
      </CarouselBlock>
    </section>
  );

  return (
    isMobile
      ? (
        <ScrollAnimation animateOnce initiallyVisible animateIn={`${styles.animateIn} animate-in`}>
          {Pagination}
          <LineIndicator
            height={lineHeight}
          />
          {renderSectionJSX()}
        </ScrollAnimation>
      ) : renderSectionJSX()
  );
};
ManagersSection.propTypes = {
  dataSlider: PropTypes.objectOf(PropTypes.any).isRequired,
  activeSection: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  setCanScrollDown: PropTypes.func.isRequired,
  setCanScrollUp: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  Pagination: PropTypes.node.isRequired,
  sectionName: PropTypes.string.isRequired,
};

export default ManagersSection;
